<template>
  <v-row>
    <v-col
      cols="12"
      md="5">
      <v-card class="pa-5">
        <v-card-title>Sources</v-card-title>
        <source-distribution :articles="sourceDistribution"></source-distribution>
      </v-card>
    </v-col>
    <v-col cols="12" md="7">
      <v-card class="pa-5">
        <v-card-title>Article Over Time</v-card-title>
        <articles-over-time :data="overTime"></articles-over-time>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card class="pa-5">
        <v-card-title>Keywords</v-card-title>
        <keyword-distribution :data="keywordCounts"></keyword-distribution>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline} from '@mdi/js'

// demos
import SourceDistribution from './statistics/SourceDistribution.vue'
import ArticlesOverTime from "@/views/articles/statistics/ArticlesOverTime";
import KeywordDistribution from "@/views/articles/statistics/KeywordDistribution";
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    KeywordDistribution,
    SourceDistribution,
    ArticlesOverTime
  },
  setup() {
    return {
      sourceDistribution: null,
      overTime: null,
      keywordCounts: null
    }
  },
  mounted() {
    axios.get(`${config.SERVER_URL}/statistics/all`).then((data) => {
      this.sourceDistribution = data.data.sources
      this.overTime = data.data["by_month"]
      this.keywordCounts = data.data["keyword_counts"]
    });
  }
}
</script>
